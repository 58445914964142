import { lazy } from 'react';

export const routesO = {
  // any state paths
  page: {
    path: '/paginas/:pagePath',
    access: 'public',
    component: lazy(() => import('../screens/Pages/Page')),
    exact: true,
    meta: {
      title: 'Página',
    },
  },
  news: {
    path: '/noticias',
    access: 'public',
    component: lazy(() => import('../screens/News/NewsList')),
    exact: true,
    meta: {
      title: 'Notícias',
    },
  },
  press: {
    path: '/imprensa',
    access: 'public',
    component: lazy(() => import('../screens/News/PressList')),
    exact: true,
    meta: {
      title: 'Imprensa',
    },
  },
  article: {
    path: '/noticias/:articleId',
    access: 'public',
    component: lazy(() => import('../screens/News/Article')),
    exact: true,
    meta: {
      title: 'Artigo',
    },
  },
  preview: {
    path: '/aprovacao/:articleId',
    access: 'public',
    component: lazy(() => import('../screens/News/Preview')),
    exact: true,
    meta: {
      title: 'Aprovação',
    },
  },
  pressArticle: {
    path: '/imprensa/:articleId',
    access: 'public',
    component: lazy(() => import('../screens/News/PressArticle')),
    exact: true,
    meta: {
      title: 'Artigo - Imprensa',
    },
  },
  content: {
    path: '/conteudo/:contentId',
    access: 'public',
    component: lazy(() => import('../screens/DynamicPage/DynamicPage')),
    exact: true,
    meta: {
      title: 'Conteúdo',
    },
  },
  FAQ: {
    path: '/perguntas-frequentes',
    access: 'public',
    component: lazy(() => import('../screens/FAQ/FAQ')),
    exact: true,
    meta: {
      title: 'Perguntas Frequentes',
    },
  },
  privacyPolicy: {
    path: '/politicas-de-privacidade',
    access: 'public',
    component: lazy(() => import('../screens/FAQ/FAQ')),
    exact: true,
    meta: {
      title: 'Políticas de Privacidade',	
    },
  },

  // VVVVVVV ABAIXO caminho não revisitados VVVVVVV

  sso: {
    path: '/sso',
    access: 'public',
    component: lazy(() => import('../screens/Login/Sso')),
    exact: true,
    meta: {
      title: 'SSO',
    },
  },
  terms: {
    path: '/termos-de-uso',
    access: 'public',
    component: lazy(() => import('../screens/Legal/Terms')),
    exact: true,
    meta: {
      title: 'Termos de Uso',
    },
  },
  termVersion: {
    path: '/termos-de-uso/:id',
    access: 'public',
    component: lazy(() => import('../screens/Legal/Terms')),
    exact: true,
    meta: {
      title: 'Termos de Uso',
    },
  },
  privacy: {
    path: '/politica-de-privacidade',
    access: 'public',
    component: lazy(() => import('../screens/Legal/Privacy')),
    exact: true,
    meta: {
      title: 'Política de Privacidade',
    },
  },
  definePassword: {
    path: '/definir-senha',
    access: 'public',
    component: lazy(() => import('../screens/Login/DefinePassword')),
    exact: true,
    meta: {
      title: 'Definir Senha',
    },
  },
  caseNew: {
    path: '/abrir-chamado',
    access: 'public',
    component: lazy(() => import('../screens/Case/CaseNew')),
    exact: true,
    meta: {
      title: 'Abrir Chamado',
    },
  },
  logout: {
    path: '/logout',
    access: 'public', // logout é public pra ignorar restrições de código de acesso e termos de uso
    component: lazy(() => import('../screens/Login/Logout')),
    exact: true,
    meta: {
      title: 'Logout',
    },
  },

  // logged paths
  home: {
    path: '/home',
    access: 'logged',
    componentByType: {
      default: lazy(() => import('../screens/Home/Home')),
    },
    exact: true,
    meta: {
      title: 'Início',
    },
  },
  profile: {
    path: '/meu-cadastro',
    access: 'logged',
    component: lazy(() => import('../screens/Login/Profile')),
    exact: true,
    meta: {
      title: 'Meu cadastro',
    },
  },
  securityProfile: {
    path: '/etapa-de-seguranca',
    access: 'logged',
    component: lazy(() => import('../screens/Login/SecurityProfile')),
    exact: true,
    meta: {
      title: 'Cadastro - Etapa de Segurança',
    },
  },
  photo: {
    path: '/meu-cadastro/foto',
    access: 'logged',
    component: lazy(() => import('../screens/Login/Profile')),
    exact: true,
    meta: {
      title: 'Meu cadastro - Foto',
    },
  },
  closeAccount: {
    path: '/meu-cadastro/encerrar-conta',
    access: 'logged',
    component: lazy(() => import('../screens/Login/Profile')),
    exact: true,
    meta: {
      title: 'Encerrar conta',
    },
  },
  changePassword: {
    path: '/meu-cadastro/alterar-senha',
    access: 'logged',
    component: lazy(() => import('../screens/Login/Profile')),
    exact: true,
    meta: {
      title: 'Alterar senha',
    },
  },
  accessCode: {
    path: '/codigo-de-acesso',
    access: 'logged',
    component: lazy(() => import('../screens/Login/AccessCode')),
    exact: true,
    meta: {
      title: 'Código de Acesso',
    },
  },
  /* TODO - remover todos os arquivos associados à esta rota
  myAccount: {
    path: '/minha-conta',
    access: 'logged',
    component: lazy(() => import('../screens/MyAccount/MyAccount')),
    exact: true,
    meta: {
      title: 'Minha Conta',
    },
  },
  */
  socioSSO: {
    path: '/seja-socio',
    access: 'public',
    componentByType: {
      default: lazy(() => import('../screens/Socio/SocioSSO')),
    },
    exact: true,
    meta: {
      title: 'Seja Sócio',
    },
  },

  // exclusively unlogged paths
  homeDeslogada: {
    path: '/',
    access: 'unlogged',
    component: lazy(() => import('../screens/Home/Home')),
    exact: true,
    meta: {
      title: 'Home',
    },
  },
  login: {
    path: '/login',
    access: 'unlogged',
    component: lazy(() => import('../screens/Login/Login')),
    exact: true,
    meta: {
      title: 'Entrar',
    },
  },
  linkLogin: {
    path: '/conectar-conta',
    access: 'public',
    component: lazy(() => import('../screens/Login/LinkLogin')),
    exact: true,
    meta: {
      title: 'Conectar conta',
    },
  },
  forgotPassword: {
    path: '/redefinir-senha',
    access: 'unlogged',
    component: lazy(() => import('../screens/Login/ForgotPassword')),
    exact: true,
    meta: {
      title: 'Redefinir senha',
    },
  },
  forgotPasswordSuccess: {
    path: '/redefinir-senha/sucesso',
    access: 'unlogged',
    component: lazy(() => import('../screens/Login/ForgotPasswordSuccess')),
    exact: true,
    meta: {
      title: 'Redefinir senha - Sucesso',
    },
  },
  signUp: {
    path: '/cadastro',
    access: 'unlogged',
    component: lazy(() => import('../screens/Login/SignUp')),
    exact: true,
    meta: {
      title: 'Cadastro',
    },
  },
  signUpPassword: {
    path: '/cadastro/senha',
    access: 'unlogged',
    component: lazy(() => import('../screens/Login/SignUpPassword')),
    exact: true,
    meta: {
      title: 'Cadastro - Senha',
    },
  },



  noMatch: { // Esta deve ser a última rota cadastrada
    path: '*',
    access: 'public',
    component: lazy(() => import('../screens/NoMatch/NoMatch')),
    meta: {
      title: 'Página não encontrada',
    },
  },
};

const routes = Object.entries(routesO).map(([key, value]) => value);

export default routes;