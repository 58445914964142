import { createSlice } from '@reduxjs/toolkit';

const loyaltySlice = createSlice({
  name: 'loyalty',
  initialState: {
    benefitsToShow: [],
  },
  reducers: {
    setBenefitsToShow(state, action) {
      state.benefitsToShow = action.payload;
    },
  },
});

export const { setBenefitsToShow } = loyaltySlice.actions;

export default loyaltySlice.reducer;